<template>
  <b-container fluid>
    <b-row class="text-white bg-kpmg-dark-blue py-4 mb-4">
      <b-col>
        <h1 class="kstyle">Regulatory Signals</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-container class="text-center" v-show="loading">
        <h1><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></h1>
      </b-container>
      <b-container class="bg-white" v-show="!loading">
        <b-row>
          <b-col>
            <b-tabs>
              <b-tab title="Active" active key="tab1" id="tab1">
                <h2 class="font-kpmg font-size-44">Most active topics</h2>
                <div v-for="section in dashboard.activetopics" :key="'section' + section.days">
                  <h5 class="mt-5 mb-2">{{ section.days }} days</h5>
                  <div :id="'echart' + section.days" style="width: 1000px; height: 300px;"></div>
                  <div v-for="item in section.topics" :key="'active' + section.days + '-' + item.id">
                    <router-link :to="{ name: 'Topic', params: { id: item.regtopic_id } }">{{ item.name }} ({{ item.count }})</router-link>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Trending" key="tab2" id="tab2">
                <h2 class="font-kpmg font-size-44">Significant change in activity</h2>
                <div id="trendchart" style="width: 1000px; height: 600px;"></div>
                <h5 class="mt-5 mb-2">Trending up</h5>
                <div v-for="item in dashboard.trending.up" :key="'trendup-' + item.id">
                  <router-link :to="{ name: 'Topic', params: { id: item.id } }">{{ item.name }}</router-link>
                </div>
                <h5 class="mt-5 mb-2">Trending down</h5>
                <div v-for="item in dashboard.trending.down" :key="'trenddown-' + item.id">
                  <router-link :to="{ name: 'Topic', params: { id: item.id } }">{{ item.name }}</router-link>
                </div>
              </b-tab>
              <b-tab title="Curve" key="tab3" id="tab3">
                <h2 class="font-kpmg font-size-44">Regulatory curve</h2>
                <b-form-select v-model="selected" :options="options"></b-form-select>
                  <b-row align-v="center" class="m-2">
                    <label for="example-datepicker" class="mb-0">start date:</label>
                    <b-form-datepicker
                      id="statDatePicker"
                      v-model="startDate"
                      class="m-2 datepicker"
                      :date-format-options="{ year: 'numeric', month: 'long', day: '2-digit'}"
                      no-flip
                    ></b-form-datepicker>
                    <label for="example-datepicker" class="mb-0 ml-2">end date:</label>
                    <b-form-datepicker
                      id="endDatePicker"
                      v-model="endDate"
                      class="m-2 datepicker"
                      :date-format-options="{ year: 'numeric', month: 'long', day: '2-digit'}"
                      no-flip
                    ></b-form-datepicker>
                    <b-button class="ml-2" @click="getData">apply</b-button>
                    <b-button class="ml-2" @click="reset">reset</b-button>
                  </b-row>
                <div id="curvebar" style="width: 900px; height: 300px; margin-top: 30px; margin-bottom: 30px;"></div>
                <div id="curvecum" style="width: 900px; height: 300px;"></div>
              </b-tab>
              <b-tab title="Insights" key="tabInsights" id="tabInsights" lazy>
                <insights-dashboard />
              </b-tab>
              <b-tab title="MAR Risks" key="tab4" id="tab4" lazy>
                <mar-dashboard />
              </b-tab>
              <b-tab title="Final rules risk" key="tab5" id="tab5" lazy>
                <final-rules-dashboard></final-rules-dashboard>
              </b-tab>
              <b-tab title="Excluded topics" key="tab6" id="tab6">
                <h2 class="font-kpmg font-size-44">Topics excluded from signals</h2>
                <div v-for="topic in dashboard.topicsExcluded" :key="'excludedtopic' + topic.id">
                  <router-link :to="{ name: 'Topic', params: { id: topic.id } }">{{ topic.name }}</router-link>
                </div>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </b-container>
    </b-row>
  </b-container>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import InsightsDashboard from './InsightsDashboard.vue'
import MarDashboard from './MarDashboard.vue'
import FinalRulesDashboard from './FinalRulesDashboard.vue'
let echarts = require('echarts')

let theme = { color: ['#00338D', '#005EB8', '#0091DA', '#483698', '#470A68', '#6D2077', '#00A3A1', '#009A44', '#43B02A', '#EAAA00'] }

echarts.registerTheme('kpmg', theme)

export default {
  components: { InsightsDashboard, MarDashboard, FinalRulesDashboard },
  name: 'StartDashboard',
  created: function () {
    this.$stat.log({ page: 'signals', action: 'open signals' })
    this.load()
  },
  data () {
    return {
      loading: true,
      dashboard: null,
      options: [],
      selected: 1,
      startDate: moment().subtract(3, "years").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD")
    }
  },
  methods: {
    load: async function () {
      try {
        let apiName = 'cosmos'
        let path = `/signals/start`
        let dashboard = await this.$Amplify.API.get(apiName, path)
        _.forEach(dashboard.activetopics, function (v) {
          let d = _.map(v.topics, function (e) {
            return { name: e.name, value: e.count }
          })
          let chart = {
            tooltip: { show: true },
            series: [
              {
                type: 'treemap',
                data: d,
                breadcrumb: { show: false }
              }
            ]
          }
          v.chart = chart
        })
        this.dashboard = dashboard
        this.options = _.map(this.dashboard.topics, (v) => {
          return { value: v.id, text: v.name }
        })
        this.loading = false
        _.delay(() => {
          _.forEach(dashboard.activetopics, function (v) {
            echarts.init(document.getElementById('echart' + v.days), 'kpmg').setOption(v.chart)
          })
          this.chartTrending()
          this.chartCurve()
        }, 1000)
      } catch (e) {
        this.$logger.warn('getCategories loading error' + e)
      }
    },
    getData: async function () {
      try {
        // let now = moment()
        let apiName = 'cosmos'
        let path = `/topic/curve/${this.selected}/${this.startDate}/${this.endDate}`
        let curve = await this.$Amplify.API.get(apiName, path)
        this.dashboard.curve = curve
        this.chartCurve()
      } catch (e) {
        this.$logger.warn('getCategories loading error' + e)
      }
    },
    reset: function () {
      this.startDate = moment().subtract(3, "years").format("YYYY-MM-DD")
      this.endDate = moment().format("YYYY-MM-DD")
      this.getData()
    },
    chartCurve: function () {
      let dataTime = []
      let data = []
      let dataCum = []
      let cum = 0
      _.forEach(this.dashboard.curve, function (v) {
        cum = cum + _.toNumber(v.count)
        dataTime.push(moment(v.date).format('YYYY-MM-DD'))
        data.push({ name: moment(v.date).format('YYYY-MM-DD'), value: [moment(v.date).format('YYYY-MM-DD'), v.count] })
        dataCum.push({ name: moment(v.date).format('YYYY-MM-DD'), value: [moment(v.date).format('YYYY-MM-DD'), cum] })
      })
      // set x-axis start/end for curve chart
      const firstDate = data[0]
      if (firstDate !== this.startDate) {
        data.unshift({ name: this.startDate, value: [this.startDate, 0] })
      }
      const lastDate = data[data.length - 1]
      if (lastDate !== this.endDate) {
        data.push({ name: this.endDate, value: [this.endDate, 0] })
      }
      // set x-axis start/end for curve cumulative chart
      const cumFirstDate = dataCum[0]
      if (cumFirstDate !== this.startDate) {
        dataCum.unshift({ name: this.startDate, value: [this.startDate, 0] })
      }
      const cumLastDate = dataCum[dataCum.length - 1]
      if (cumLastDate !== this.endDate) {
        dataCum.push({ name: this.endDate, value: [this.endDate, 0] })
      }

      let option = {
        grid: {
          top: 30,
          bottom: 30,
          left: 50,
          right: 50
        },
        xAxis: {
          type: 'time',
          // data: dataTime,
          splitLine: {
            show: false
          },
          splitArea: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          splitArea: {
            show: false
          }
        },
        series: [{
          data: data,
          type: 'bar',
          barCategoryGap: 0
        }]
      }
      let optionCum = {
        grid: {
          top: 30,
          bottom: 30,
          left: 50,
          right: 50
        },
        xAxis: {
          type: 'time',
          // data: dataTime,
          splitLine: {
            show: false
          },
          splitArea: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          splitArea: {
            show: false
          }
        },
        series: [{
          data: dataCum,
          type: 'line',
          symbol: 'none',
          areaStyle: {
            opacity: 1
          }
        }]
      }
      echarts.init(document.getElementById('curvebar'), 'kpmg').setOption(option)
      echarts.init(document.getElementById('curvecum'), 'kpmg').setOption(optionCum)
    },
    chartTrending: function () {
      let valuesup = []
      let topicsup = []
      _.forEach(this.dashboard.trending.up, function (v) {
        valuesup.push({ value: _.min([v.trend, 100]), label: { position: 'right' } })
        topicsup.push(v.name)
      })
      valuesup = _.reverse(valuesup)
      topicsup = _.reverse(topicsup)
      let values = []
      let topics = []
      _.forEach(this.dashboard.trending.down, function (v) {
        values.push({ value: v.trend, label: { position: 'right' } })
        topics.push(v.name)
      })
      values = _.concat(values, valuesup)
      topics = _.concat(topics, topicsup)
      let optionTrend = {
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'shadow' }
        },
        grid: {
          top: 0,
          bottom: 0,
          left: 10,
          width: 100
        },
        xAxis: {
          type: 'value',
          show: false,
          splitLine: { lineStyle: { type: 'dashed' } }
        },
        yAxis: {
          type: 'category',
          axisLine: { show: false },
          axisLabel: { show: false },
          axisTick: { show: false },
          splitLine: { show: false },
          data: topics
        },
        series: [
          {
            name: 'trend',
            type: 'bar',
            stack: '总量',
            label: {
              show: true,
              formatter: '{b}'
            },
            data: values
          }
        ]
      }
      echarts.init(document.getElementById('trendchart'), 'kpmg').setOption(optionTrend)
    }
  },
  watch: {
    selected: async function () {
      this.getData()
    }
  }
}
</script>
<style>
.datepicker {
  max-width: 253px;
}
</style>
